import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { get_one_gatepass, getOnegatepassSelector } from "@services/redux";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem } from "@helpers/storage";

const GatePassView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const { item: gate_pass } = useSelector(getOnegatepassSelector);

  useEffect(() => {
    dispatch(get_one_gatepass({ id: id }));
  }, [id]);

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
    }
  };

  const roles = JSON.parse(retrieveItem("roles"));

  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {"Gate Pass Details"}
        </Text>
      </Box>
      <Box>
        <Card mb="5">
          <VStack space="5">
            <HStack justifyContent={"flex-end"} space="4" right="6">
              <Box>
                <Text bold>Status:</Text>
              </Box>
              <Box>
                <Text>{formatStatus(gate_pass?.status)}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Pass Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.pass_number} </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Pass:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {gate_pass?.pass_type?.parent_pass_type?.parent_pass_type
                        ?.name
                        ? gate_pass?.pass_type?.parent_pass_type
                            ?.parent_pass_type?.name
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Pass Type:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.pass_type?.parent_pass_type?.name}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Pass Subtype:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.pass_type?.name}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Issued to:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{gate_pass?.name}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Vehicle Details:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {gate_pass?.vehicle_make} -{" "}
                          {gate_pass?.vehicle_number}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Previous Pass Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.previous_pass_number} </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Previous Pass Issued date:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {formatDisplay(gate_pass?.previous_issued_date)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Issued Date:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{formatDisplay(gate_pass?.issued_date)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Valid Till:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{formatDisplay(gate_pass?.valid_till)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            {gate_pass?.pass_type?.name !== "Visitors" && (
              <HStack>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <HStack>
                        <Box width="35%">
                          <Text bold>Department:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{gate_pass?.department?.name}</Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack>
                        <Box width="35%">
                          <Text bold>Designation:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{gate_pass?.designation?.name}</Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            )}
          </VStack>
        </Card>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          Back
        </Button>
      </HStack>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GatePassView;
