import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
} from "native-base";
import AntDesign from "react-native-vector-icons/dist/AntDesign";
import Entypo from "react-native-vector-icons/dist/Entypo";
import FooterBar from "@views/components/ui/footer_bar/footer_bar";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { ROUTES } from "@views/routes/my_routes";
// import FooterBar from "@views/components/ui/footer_bar/footer_bar";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, loginSelector } from "@services/redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import logo from "@assets/images/logo.png";
import building from "@assets/images/building.png";
import { Link } from "native-base";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import login_pic from "@assets/images/Assembly_1.jpg";
import FooterBars from "@views/components/ui/footer_bar/footerbar";
import Footer from "../footer/footer";
import Header from "../Header/header";
import { showToast } from "@helpers/toast";
import { MdOutlineRefresh } from "react-icons/md";
import { encryptRequest } from "@helpers/crypto";
import { getCaptchaSelector, get_captcha } from "@services/redux";
import { GifPlayer } from "react-gif-player";
import Reload from "@assets/reload.gif";
export function SignInForm({ props }) {
  // add next router here
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canvasRef = useRef();
  const {
    captcha,
    error: captcha_error,
    loading: captcha_loading,
  } = useSelector(getCaptchaSelector);
  const { item, authorize, loading, status, error } =
    useSelector(loginSelector);
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [captcha_input, setCaptcha] = useState("");

  const [showPass, setShowPass] = useState(false);
  // const captcha = "123456";
  const handleLogin = () => {
    if (captcha_input === captcha)
      dispatch(
        login({
          username: text,
          password: encryptRequest(pass),
          captcha: captcha_input,
        })
      );
    else
      showToast({
        message: t("Invalid Captcha"),
        type: "error",
      });
  };
  useEffect(() => {
    if (captcha_error?.message == "Too many requests")
      showToast({
        type: "error",
        message: `Too many requests`,
      });
  }, [captcha_error]);
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const getCaptcha = () => {
    dispatch(get_captcha());
  };
  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);
  useEffect(() => {
    getCaptcha();
  }, []);
  const createCaptcha = () => {
    var canvas = canvasRef.current;
    var context = canvas.getContext("2d");
    context.clearRect(0, 0, 100, 100);
    context.font = "18px Georgia";
    context.fillText(captcha, 0, 30);
  };
  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error,
      });
    } else if (authorize) {
      history.push({
        pathname: `${ROUTES.DASHBOARD}`,
        state: item,
      });
    }
  }, [error, authorize]);
  return (
    <VStack space={3} flex={1} justifyItems={"center"}>
      <Hidden till="md">
        <Box mx="auto">
          <Text fontSize="lg" fontWeight="bold" color={"#0077c0"}>
            {t("WELCOME")}
          </Text>
        </Box>
      </Hidden>
      <Box mx="auto" width="75%" backgroundColor={"white"}>
        <FloatingLabelInput
          isRequired
          label="Username"
          labelColor="#9ca3af"
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          borderRadius="4"
          autoFocus={true}
          defaultValue={text}
          height="10"
          onChangeText={(txt) => setText(txt)}
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
          onKeyPress={handleKeypress}
        />
      </Box>
      <Box mx="auto" width="75%" backgroundColor={"white"}>
        <FloatingLabelInput
          isRequired
          type={showPass ? "" : "password"}
          label="Password"
          height="10"
          borderRadius="4"
          labelColor="#9ca3af"
          onKeyPress={handleKeypress}
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          defaultValue={pass}
          onChangeText={(txt) => setPass(txt)}
          InputRightElement={
            <IconButton
              variant="unstyled"
              icon={
                showPass ? (
                  <AiFillEyeInvisible
                    size={18}
                    color={useColorModeValue("primary.900", "primary.900")}
                  />
                ) : (
                  <AiFillEye size={18} />
                )
              }
              onPress={() => {
                setShowPass(showPass ? false : true);
              }}
            />
          }
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />
      </Box>
      <Box mx="auto" width="75%">
        <HStack flex="1" space={10}>
          <HStack mt="-10px" alignItems={"center"}>
            <canvas
              className="MobileCanvas"
              width="100"
              height="50"
              ref={canvasRef}
            />
            <MdOutlineRefresh
              onClick={getCaptcha}
              size={20}
              style={{ cursor: "pointer" }}
            />
            {/* <GifPlayer
              gif={Reload}
              still={captcha_loading}
              autoplay={captcha_loading}
            /> */}
          </HStack>
          <Box flex="1">
            <FloatingLabelInput
              isRequired
              label="Captcha"
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              autoFocus={false}
              height="10"
              onChangeText={(txt) => setCaptcha(txt)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
              onKeyPress={handleKeypress}
            />
          </Box>
        </HStack>
      </Box>
      <Box mx="auto" width="100">
        <Button
          isLoading={loading}
          flex={1}
          mt="5"
          size="md"
          borderRadius="4"
          _text={{
            fontWeight: "medium",
          }}
          _light={{
            bg: "#0077c0",
          }}
          _hover={{
            bg: "#0077c0",
          }}
          _dark={{
            bg: "primary.700",
          }}
          onPress={handleLogin}
          // ref={(node) => (this?.btn = node)}
          /* onPress function naviagteTo:"OTP" (next/Router) */
        >
          SIGN IN
        </Button>
      </Box>
    </VStack>
  );
}
export default function SignIn(props) {
  const { t } = useTranslation();
  return (
    <Box
      minH={{
        md: "700px",
      }}
    >
      {/* <Header /> */}
      <Box
        safeAreaTop
        _light={{
          bg: "coolGray.700",
        }}
        _dark={{
          bg: "coolGray.700",
        }}
      />
      <Center
        my="auto"
        padding={"40px"}
        minH={{
          base: "0",
          md: "500px",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bgImage: `url(${login_pic})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="85%"
          maxW={{
            md: "900px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          {/* <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="2">
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome back,
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="normal"
                  _dark={{
                    color: "coolGray.400",
                  }}
                  _light={{
                    color: "primary.300",
                  }}
                >
                  {t("sign_header")}
                </Text>
              </VStack>
            </VStack>
          </Hidden> */}
          <Hidden till="md">
            <Center
              flex="1"
              bg="#141414"
              opacity={"0.8"}
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                h="18"
                size="40"
                alt="NativeBase Startup+ "
                resizeMode={"contain"}
                source={logo}
              />
              <Text color="white" fontSize="lg" fontWeight={"600"}>
                {t("PD")}
              </Text>
            </Center>
          </Hidden>
          <Box
            flex={1}
            px="6"
            py="9"
            _light={{
              bg: "white",
              // opacity: "0.9",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            space="3"
            justifyContent="center"
            borderTopRightRadius={{
              base: "2xl",
              md: "xl",
            }}
            borderTopLeftRadius={{
              base: "2xl",
              md: "0",
            }}
            borderBottomRightRadius={{
              base: "xl",
              md: "xl",
            }}
            borderBottomLeftRadius={{
              base: "xl",
              md: "0",
            }}
          >
            <SignInForm props={props} />
          </Box>
        </Stack>
      </Center>
      {/* <Footer /> */}
    </Box>
  );
}
