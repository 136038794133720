import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  Select,
  Radio,
  Stack,
  Input,
  VStack,
  Image,
  Link,
  Button,
  Text,
  Divider,
  Center,
} from "native-base";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import ScrollView from "@views/components/ui/scroll_view";
import Footer from "../footer/footer";
import Header from "../Header/header";
import { backgroundSize } from "styled-system";
// import banner from "@assets/icons/logo.png";

import Homebanner from "@assets/images/Home_banner.jpg";
import HomeDashboard from "./home_dashboard";

const HomeDetails = (props) => {
  const history = useHistory();
  return (
    <ScrollView>
      <Box flex="1" w="100%">
        {/* <Box position="sticky" top="0px" zIndex="2" bg="white" mx="6">
          <h2 fontSize="xl">
            <b>{t("self_estimation_form")}</b>
          </h2>
        </Box> */}
        <VStack>
          <Header />
          <HStack>
            <Image
              source={Homebanner}
              alt="Alternate Text"
              // size="500px"
              width="100%"
              // height="500px"
              height={{
                base: "200px",
                md: "600px",
              }}
            />
          </HStack>
          <Center>
            <Box
              alignItems="center"
              width={{
                base: "85%",
                md: "65%",
              }}
            >
              <h2
                style={{
                  marginTop: "15px",
                }}
              >
                <b>Welcome to Public Motor Vehicle Department </b>
              </h2>

              <VStack py="3">
                {/* <Text fontSize={17} textAlign="justify">
                  The Public Department which functions under the direct control
                  of the Chief Secretary deals with important matters concerning
                  the General Administration of the State.
                </Text>
                <Text fontSize={17} textAlign="justify">
                  This department sends recommendations to Government of India
                  for Central Government’s Gallantry awards. The work relating
                  to choosing of recipients of Awards such as Anna Medal, Kottai
                  Ameer Award for Communal Harmony, Kalpana Chawla Award and
                  other state awards are also taken up by this department.
                </Text>

                <Text fontSize={18} textAlign="justify">
                  This department deals with matters relating to extension of
                  stay in India for all Foreigners (except Pakistan and
                  Bangladesh Nationals) who come to Tamil Nadu. In case of Sri
                  Lankan Nationals, staying in Tamil Nadu, extension of stay in
                  India is granted only after obtaining the orders of Government
                  of India.
                </Text> */}

                <Box marginTop={"40px"} marginBottom="40px">
                  <HomeDashboard />
                </Box>
              </VStack>
            </Box>
          </Center>

          <Footer />
        </VStack>
      </Box>
    </ScrollView>
  );
};
export default HomeDetails;
