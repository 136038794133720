import { gql } from "@apollo/client";

export const getOTPMutation = gql`
  query getOTP($mobile: String!) {
    getOTP(mobile: $mobile) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const verifyOTPQuery = gql`
  query verifyOTP($mobile: String!, $otp: String!) {
    verifyOTP(mobile: $mobile, otp: $otp) {
      userId
      token
      username
      name
      mobile
      force_password_change
      last_login_datetime
      roles {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;
