import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  Select,
  Radio,
  Stack,
  Input,
  VStack,
  Image,
  Link,
  Button,
  Text,
  Divider,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import ScrollView from "@views/components/ui/scroll_view";
// import banner from "@assets/icons/logo.png";
import logo from "@assets/images/logo.png";

const Header = (props) => {
  const history = useHistory();
  return (
    <>
      <Stack
        justifyContent="flex-end"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        px="5"
        style={{
          backgroundImage:
            "linear-gradient(406deg, rgb(23, 153, 199) 20%, rgb(64, 145, 97) 80%)",
        }}
        height="8"
      >
        <HStack flex={1} space={10} justifyContent="flex-end" mt="1">
          <Box my="auto" alignSelf={"center"}>
            <Text
              height="30px"
              color="white"
              variant="none"
              _hover={{ bg: "#0e669d" }}
              cursor="pointer"
              // style={{
              //   textDecoration: "underline",
              // }}
              onClick={() => {
                history.push(ROUTES.SCREEN_READER_ACCESS);
              }}
            >
              Screen Reader Access
            </Text>
          </Box>
        </HStack>
      </Stack>
      <Divider />
      <Stack
        justifyContent="flex-end"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        p="2"
        style={{
          backgroundImage:
            "linear-gradient(406deg, rgb(23, 153, 199) 20%, rgb(64, 145, 97) 80%)",
        }}
      >
        <HStack space="" flex={1} alignItems="center">
          <img src={logo} alt="tamil nadu" height="85px" />
          <Text
            flex={1}
            verticalAlign="middle"
            fontSize="20px"
            mx="2"
            fontWeight={"Bold"}
            color="white"
          >
            Public Department <br />
            Government of Tamil Nadu
          </Text>
          {/* <Image
              source={{
                uri: "http://www.nrtamils.tn.gov.in/static/images/en-tn-logo.png",
              }}
              alt="Alternate Text"
              // size="200px"
              // height="50px"
              // width="200"
              mt="3"
              height={{
                base: "40px",
                xs: "32px",
                sm: "40px",
                md: "50px",
                xl: "60px",
              }}
              width={{
                base: "30px",
                xs: "0px",
                sm: "30px",
                md: "200px",
                xl: "240px",
              }}
            /> */}
        </HStack>
        <Box my="auto" mr="10px" mb="10px">
          <HStack flex={1} justifyContent="flex-end" space="3">
            <Text
              height="40px"
              color="white"
              fontSize={"lg"}
              variant="none"
              _hover={{ bg: "#0e669d" }}
              cursor="pointer"
              bold
              // style={{
              //   textDecoration: "underline",
              // }}
              onClick={() => {
                history.push(ROUTES.HOME_DETAILS);
              }}
            >
              Home
            </Text>

            <Text height="50px" color="white" fontSize={"lg"}>
              |
            </Text>

            <Text
              onClick={() => history.push(ROUTES.LOGIN)}
              height="40px"
              color="white"
              fontSize={"lg"}
              variant="none"
              _hover={{ bg: "#0e669d" }}
              cursor="pointer"
              bold
            >
              Login
            </Text>
          </HStack>
        </Box>
      </Stack>
    </>
  );
};
export default Header;
